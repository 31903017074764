import Image from "next/image";

export default function Logos() {
    return <div className={'bg-white'}>
        <div className={'py-4 sm:py-16 lg:py-16 max-w-5xl mx-auto '}>

            <h3 className={'text-center text-3xl'}>
                ―
                Awesome Brands trust us. Why don&apos;t you?
                ―
            </h3>

            <Image src={"/images/logos.webp"}
                   width={5357}
                   height={455}
                   className={"grayscale my-6 px-4"}
                   alt={"Logos"} />


        </div>
    </div>
}